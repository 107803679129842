<template>
    <section class="gold-purchase">
        {{ message }}
    </section>
</template>

<script>
  export default {
    data () {
      return {
        message: ''
      };
    },
    created() {
        this.message = this.$t('cancelPayment');
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
    }
}
</script>